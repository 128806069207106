import React from 'react'

import './css/app.scss'
import Layout from "../components/layout";
import aboutImage from "../images/index/about.svg";
import SEO from "../components/seo";

const SobrePage = () => (
  <Layout>
    <SEO title="Sobre Nós" description="Uma empresa que tem como objetivo trazer soluções tecnológicas para o seu negócio. " keywords={[`sobre`, `system code`]} />
    <div className="main" role="main">
      <div className="ui-hero hero-lg ui-gradient-sc ui-waves hero-svg-layer-1 sp-horizontal sp-has-buttons">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center" data-vertical_center="true" data-vertical_offset="16">
              <h1 className="animate fade-in-up-big" data-show="fade-in-up-big" data-delay="100">
                Sobre Nós
              </h1>
              <p className="text-white">Uma empresa que tem como objetivo trazer soluções tecnológicas para o seu negócio.</p>
            </div>
          </div>
        </div>
      </div>

      <div id="features" className="section">
        <div className="container ui-icon-blocks ui-blocks-h icons-lg">
          <div className="section-heading">
            <h3 className="heading text-green-sc">
              OS BASTIDORES DO SEU SUCESSO
            </h3>
            <div className="row">
            <div class="col-md-6">
              <p className="paragraph text-justify">
                A <strong>System Code</strong> atua no mercado desde 2014, oferecendo aos seus clientes soluções corporativas, totalmente integradas, utilizando modernos conceitos de gestão, facilitando os processos no dia-a-dia das empresas e gerando as informações necessárias para tomada de decisões.
              </p>
              <p className="paragraph text-justify">
                É composta por uma equipe de profissionais altamente capacitados, distribuída nas áreas de análise, desenvolvimento, suporte, implementação e consultoria com conhecimento técnico e conceitos acadêmicos agregados, fundamental para um projeto de sucesso.
              </p>
            </div>
            <div className="col-md-6">
              <img src={aboutImage} alt="System Code"
                   data-uhd data-max_width="1000" className="responsive-on-lg" />
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SobrePage
